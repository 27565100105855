@use '@geneplanet/bootstrap/scss/shared/breakpoints.scss';

app-cookie-consent {
  width: 100%;
  margin: auto;
  position: fixed;
  bottom: 0;
  z-index: 1030;
  .cookie-consent-wrapper {
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }

  @include breakpoints.media-breakpoint-up(xl) {
    .cookie-consent-wrapper {
      border-radius: 1.5rem;

      width: 1152px;
      margin: auto;
      margin-bottom: 2rem;
    }
  }
}
